<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <div>
        <el-form :model="table.where" class="ele-form-search d-flexspabet" @keyup.enter.native="$refs.table.reload()"
          @submit.native.prevent>

          <div class="d-flex" style="display: flex;">
            <el-button style="height: 40px;" @click="showEdit = true" class="ele-btn-icon addbtn mb-20" size="small"
              v-if="permission.includes('sys:park:add')">添加停车场
            </el-button>

            <el-form-item label="省:" label-width="35px" class="w-170">
              <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder="请选择省"
                clearable>
                <el-option v-for="option in provArr" :label="option.name" :value="option.pid" :key="option.pid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" label-width="35px" class="w-150">
              <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid), $forceUpdate()"
                placeholder="请选择市" clearable>
                <el-option v-for="option in cityArr" :label="option.name" :value="option.cid" :key="option.cid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
              <el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
                <el-option v-for="option in districtArr " :label="option.name" :value="option.aid" :key="option.aid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon"  v-if="permission.includes('sys:park:index')">搜索</el-button>
            </el-form-item>
            <el-form-item style="margin-left: 10px;">
              <el-button class="ele-btn-icon" @click="(table.where = {}) && $refs.table.reload()">重置</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      
      
      
      <!-- 数据表格 -->
      <ele-data-table ref="table" :fit="true" :config="table" :choose.sync="choose" stripe >
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="编号" align="center" fixed="left" width="50"
            show-overflow-tooltip />
          <el-table-column prop="pid" label="所属区域" align="center" show-overflow-tooltip >
            <template slot-scope="{row}">{{ row.cn_name}}</template>
          </el-table-column>

          <el-table-column prop="park_name" label="停车场名称" align="center" show-overflow-tooltip  />
          <el-table-column prop="park_address" label="停车场地址" align="center" show-overflow-tooltip  />
            <el-table-column prop="cn_type" label="停车场类型" align="center" show-overflow-tooltip  />
         
          <el-table-column label="创建时间" align="center" show-overflow-tooltip >
            <template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作"  align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" slot="reference" icon="el-icon-edit" type="primary" :underline="false"
                v-if="permission.includes('sys:park:edit')">编辑</el-link>
              <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger" :underline="false"
                v-if="permission.includes('sys:park:delete')">删除</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    
    <!--添加/编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id ? '查看停车场' : '添加停车场'" :visible.sync="showEdit"
      @closed="editForm = {}" :destroy-on-close="true" :lock-scroll="false" width="60%">
      <el-card shadow="never" style="padding: 0px 40px;">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="180px">
          
          <el-form-item class="choose-area" label="请选择地区：" prop="pid">
            <el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
              class="selectStyle mr-10" clearable>
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
              </el-option>
            </el-select>
            
            <el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)" placeholder="请选择市"
              class="selectStyle mr-10" clearable>
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
              </el-option>
            </el-select>
            
            <el-select style="margin-bottom: 0px;" v-model="editForm.aid"
              @change="$forceUpdate(), handleDis(editForm.aid)" placeholder="请选择区/县" class="selectStyle mr-10"
              clearable>
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="停车场名称" width="200" prop="park_name" >
            <el-input style="width: 500px;" v-model="editForm.park_name" controls-position="right" placeholder="" clearable class="input163" :rules="editRules" />
          </el-form-item>
          
          <el-form-item label="停车场类型" prop="park_type">
            <el-radio-group v-model="editForm.park_type" :rules="editRules">
              <el-radio :label="1">地面停车场</el-radio>
              <el-radio :label="2">地下停车场</el-radio>
              <el-radio :label="3">路边停车场</el-radio>
            </el-radio-group>
          </el-form-item>

         

          <el-form-item label="停车场地址" prop="detailedAddress">
            <mymap :default-value="selfCity" :default-address="postForm.detailedAddress" :default-lng="postForm.longitude"  :default-lat="postForm.latitude"  @getPosition="getPosition" ref="childmap" v-if="ishas" />
          </el-form-item>








        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit = false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>





<script>
  import mymap from '@/components/Amap'

  import {
    mapGetters
  } from "vuex";


  export default {
    name: "park",
    components: {
      mymap
    },

    watch: {
      $route() {
        //切换标签时
        this.handleRefreshTable();
      }
    },


    data() {

      


      var validateAddress = (rule, value, callback) => {
        if (this.postForm.detailedAddress == '' || this.postForm.longitude == '') {
          callback(new Error('请输入停车场地址'));
        } else {
          callback();
        }
      };


      return {
        ishas:false,
        table: {
          url: 'user/parkIndex',
          where: {}
        }, 
        checkList: [{
            label: '地上停车场',
            value: 1
          },
          {
            label: '地下停车场',
            value: 2
          },
          {
            label: '路边停车场',
            value: 3
          },
        
        ],
        checkGroups: [],
        choose: [], // 表格选中数据
        showEdit: false, // 是否显示表单弹窗
        postForm: {
          detailedAddress: '',
          longitude: '',
          latitude: ''
        },
        editForm: {

        }, // 表单数据
        editRules: { // 表单验证规则
          pid: [{
            required: true,
            message: '请选择省份',
            trigger: 'change'
          }],
          park_name: [{
            required: true,
            message: '请输入停车场名称',
            trigger: 'blur'
          }],
          park_address: [{
            required: true,
            message: '请输入停车场地址',
            trigger: 'blur'
          }],
          
          park_type: [{
            required: true,
            message: '请选择停车场地址',
            trigger: 'blur'
          }],
        
         
          detailedAddress: [{
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }]
         

        },
        provArr: [],
        cityArr: [],
        districtArr: [],
        activeName: 'first',
        activeEdit: 'run-driver',
        pid: '',
        cid: '',
        aid: '',
        changePro: false,
        changeCity: false,
        changeArea: false,
        selfCity:''


      }
    },
    created() {
      this.getMapInitData();
     

      let _this = this;
      this.$http.get('/common/province_list').then(res => {
        let data = JSON.parse(res.data)
        this.provArr = data
      })
      window.onresize = function() {
        _this.handleRefreshTable();
      };

    },
    computed: {
      ...mapGetters(["permission"]),
    },
    updated() {
      //数据改变时
      this.handleRefreshTable();
    },
    mounted() {},

    methods: {
      getMapInitData(){
        this.$http.get('/user/mapInitData').then(res => {
           let resData = res.data.data;
           //console.log('55566666',resData.cityName);
           this.selfCity=resData.cityName;
           this.postForm.longitude=resData.lng;
           this.postForm.latitude=resData.lat;
           this.ishas=true;
           //console.log('5577776',this.postForm);
           
        })
      },
      handleRefreshTable() {

      },
      getPosition({
        address,
        lat,
        lng,
        name
      }) {
        this.postForm.detailedAddress = address;
        this.postForm.longitude = String(lng);
        this.postForm.latitude = String(lat);
        //console.log('ttteeeee',this.postForm);
      },



      /**
       *选择省
       **/
      handleChangeProv(e) {
        /** 获取被选省份的pid**/
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.table.where.cid = ''
          this.table.where.aid = ''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity(e) {
        if (e) {
          /** 获取被选市的cid**/
          let cid = ''
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.table.where.aid = ''
          })
        } else {
          // this.table.where.pid = ''
          this.table.where.aid = ''
        }
      },

      /**
       *修改省
       **/
      handleChangeProv1(e) {
        /** 获取被选省份的pid**/
        if (e) {
          let pid = ''
          let that = this
          this.changePro = true
          this.provArr.forEach(function(item) {
            if (item.pid == e) {
              pid = item.pid
            }
          })
          /** 根据被选省份的pid获取省市下面的市**/
          this.$http.post('/common/city_list', {
            pid: pid
          }).then(res => {
            let data = JSON.parse(res.data)
            that.cityArr = data
            /** 选择省份清空市县**/
            this.editForm.cid = ''
            this.editForm.aid = ''
            this.editForm.cname = ''
            this.editForm.aname = ''
          })
        } else {
          this.editForm.pid = ''
          this.editForm.cid = ''
          this.editForm.aid = ''
          this.editForm.pname = ''
          this.editForm.cname = ''
          this.editForm.aname = ''
        }

      },
      /**
       *修改市
       **/
      handleChangeCity1(e) {
        if (e) {
          /** 获取被选市的cid**/
          let cid = ''
          this.changeCity = true
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.editForm.aid = ''
            this.editForm.aname = ''
          })
        } else {
          // this.editForm.cname = ''
          this.editForm.aid = ''
          this.editForm.aname = ''
        }
      },
      handleDis() {
        this.changeArea = true
      },
      /**
       * 显示编辑
       */
      edit(row) {
        this.pid = row.pid
        this.cid = row.cid
        this.aid = row.aid

        this.editForm = row
        this.editForm.pid = row.pid
        this.editForm.cname = row.cid;
        this.editForm.aname = row.aid;
        this.showEdit = true;
        // this.checkGroups = JSON.parse(row.sampling_week);



        if (this.editForm.pid != '') {
          this.$http.post('/common/city_list', {
            pid: this.editForm.pid
          }).then(res => {
            let cdata = JSON.parse(res.data)
            this.cityArr = cdata
          })
        }
        if (this.editForm.cid != '') {
          this.$http.post('/common/area_list', {
            cid: this.editForm.cid
          }).then(res => {
            let adata = JSON.parse(res.data)
            this.districtArr = adata
          })
        }
        
        this.postForm.detailedAddress=row.park_address;
        this.postForm.longitude=row.park_address_lng;
        this.postForm.latitude=row.park_address_lat;
        
        

        // this.getPosition(row.park_address,row.park_address_lng, row.park_address_lat, '')

       this.$nextTick(function () {
         if(this.ishas){
           this.$refs.childmap.setAddress(row.park_address, row.park_address_lng, row.park_address_lat)
         }
      });
        

        


      },
      /**
       * 保存编辑
       */
      save() {
        this.$refs['editForm'].validate((valid) => {
          
          // this.editForm.sampling_week = JSON.stringify(this.checkGroups)
          if (this.postForm.longitude != '') {
            this.editForm.park_address = this.postForm.detailedAddress;
            this.editForm.park_address_lng = this.postForm.longitude;
            this.editForm.park_address_lat = this.postForm.latitude;

          }
          if (!this.editForm.park_address_lat) {
            this.$message.error("请输入停车场地址");
            return false;
          }
          if (valid) {

            const loading = this.$loading({
              lock: true
            });

            //console.log('77777777777777777777777777777777777777',this.editForm)
            
           
            this.$http.post('/user/parkCreate', this.editForm).then(res => {
              loading.close();
              if (res.data.code === 0) {
                this.showEdit = false;
                this.$message({
                  type: 'success',
                  message: res.data.msg
                });
                this.$refs.table.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });

          } else {
            //console.log('1111111111111111111111');
            return false;
          }
        });
      },
      /**
       * 刪除(批量刪除)
       */
      remove(row) {
        //console.log(row)
        if (!row) { // 批量删除
          if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
          let ids = this.choose.map(d => d.id);
          this.$confirm('确定要删除选中的设置吗?', '提示', {
            type: 'warning'
          }).then(() => {
            const loading = this.$loading({
              lock: true
            });
            this.$http.post('/user/parkDel', {
              id: ids
            }).then(res => {
              loading.close();
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                });
                this.$refs.table.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });
          }).catch(() => 0);
        } else { // 单个删除
          this.$confirm('确定要删除选中的设置吗?', '提示', {
            type: 'warning'
          }).then(() => {
            const loading = this.$loading({
              lock: true
            });
            this.$http.post('/user/parkDel', {
              id: row.id
            }).then(res => {
              loading.close();
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                });
                this.$refs.table.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });
          })
        }
      },
    }
  }
</script>

<style scoped>
  .ele-block>>>.el-upload,
  .ele-block>>>.el-upload-dragger {
    width: 100%;
  }

  .ele-block>>>.el-upload,
  .ele-block>>>.el-upload-dragger {
    width: 100%;
  }

  .driverForm /deep/.el-form-item__content {
    margin-left: 0 !important
  }

  /deep/.el-tabs__nav {
    margin-left: 20px
  }

  .prompt {
    color: #FF0000;
    font-size: 14px;
  }
</style>
